export default [
  {
    path: '/permissions',
    name: 'permissions',
    component: () => import('@/views/miscellaneous/ComingSoon.vue'),
    meta: {
      resource: 'Permissions',
      action: 'read',
      pageTitle: 'Permissions',
      breadcrumb: [
        {
          text: 'Settings',
        },
        {
          text: 'Permissions',
          active: true,
        },
      ],
    },
  },
  {
    path: '/help/support',
    name: 'support',
    component: () => import('@/views/miscellaneous/ComingSoon.vue'),
    meta: {
      resource: 'Support',
      pageTitle: 'Support',
      breadcrumb: [
        {
          text: 'Help',
        },
        {
          text: 'Support',
          active: true,
        },
      ],
    },
  },
  {
    path: '/settings/register-user',
    name: 'register-user',
    component: () => import('@/views/authentication/Register.vue'),
    meta: {
      resource: 'Register',
      pageTitle: 'Register User',
      breadcrumb: [
        {
          text: 'Settings',
        },
        {
          text: 'Register User',
          active: true,
        },
      ],
    },
  },
]
