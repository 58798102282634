export default [
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/error/Error404.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
    },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/authentication/Login.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  // {
  //   path: '/pages/authentication/login-v1',
  //   name: 'auth-login-v1',
  //   component: () => import('@/views/pages/authentication/Login-v1.vue'),
  //   meta: {
  //     layout: 'full',
  //   },
  // },
  // {
  //   path: '/pages/authentication/login-v2',
  //   name: 'auth-login-v2',
  //   component: () => import('@/views/pages/authentication/Login-v2.vue'),
  //   meta: {
  //     layout: 'full',
  //   },
  // },
  // {
  //   path: '/register',
  //   name: 'auth-register',
  //   component: () => import('@/views/pages/authentication/Register.vue'),
  //   meta: {
  //     layout: 'full',
  //     resource: 'Auth',
  //     redirectIfLoggedIn: true,
  //   },
  // },
  // {
  //   path: '/pages/authentication/register-v1',
  //   name: 'auth-register-v1',
  //   component: () => import('@/views/pages/authentication/Register-v1.vue'),
  //   meta: {
  //     layout: 'full',
  //   },
  // },
  // {
  //   path: '/pages/authentication/register-v2',
  //   name: 'auth-register-v2',
  //   component: () => import('@/views/pages/authentication/Register-v2.vue'),
  //   meta: {
  //     layout: 'full',
  //   },
  // },
  // {
  //   path: '/forgot-password',
  //   name: 'auth-forgot-password',
  //   component: () => import('@/views/authentication/ForgotPassword.vue'),
  //   meta: {
  //     layout: 'full',
  //     resource: 'Auth',
  //     redirectIfLoggedIn: true,
  //   },
  // },
  // {
  //   path: '/pages/authentication/forgot-password-v1',
  //   name: 'auth-forgot-password-v1',
  //   component: () => import('@/views/pages/authentication/ForgotPassword-v1.vue'),
  //   meta: {
  //     layout: 'full',
  //   },
  // },
  // {
  //   path: '/pages/authentication/forgot-password-v2',
  //   name: 'auth-forgot-password-v2',
  //   component: () => import('@/views/pages/authentication/ForgotPassword-v2.vue'),
  //   meta: {
  //     layout: 'full',
  //     resource: 'Auth',
  //   },
  // },
  // {
  //   path: '/pages/authentication/reset-password-v1',
  //   name: 'auth-reset-password-v1',
  //   component: () => import('@/views/authentication/ResetPassword-v1.vue'),
  //   meta: {
  //     layout: 'full',
  //   },
  // },
  // {
  //   path: '/pages/authentication/reset-password-v2',
  //   name: 'auth-reset-password-v2',
  //   component: () => import('@/views/pages/authentication/ResetPassword-v2.vue'),
  //   meta: {
  //     layout: 'full',
  //   },
  // },
  {
    path: '/pages/miscellaneous/coming-soon',
    name: 'misc-coming-soon',
    component: () => import('@/views/miscellaneous/ComingSoon.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/pages/miscellaneous/not-authorized',
    name: 'misc-not-authorized',
    component: () => import('@/views/miscellaneous/NotAuthorized.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
    },
  },
  {
    path: '/pages/miscellaneous/under-maintenance',
    name: 'misc-under-maintenance',
    component: () => import('@/views/miscellaneous/UnderMaintenance.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/pages/miscellaneous/error',
    name: 'misc-error',
    component: () => import('@/views/miscellaneous/Error.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/settings/account-setting',
    name: 'settings-account-setting',
    component: () => import('@/views/miscellaneous/ComingSoon.vue'),
    meta: {
      resource: 'AccountSetting',
      pageTitle: 'Account Settings',
      breadcrumb: [
        {
          text: 'Settings',
        },
        {
          text: 'Account Settings',
          active: true,
        },
      ],
    },
  },
  // {
  //   path: '/pages/profile',
  //   name: 'pages-profile',
  //   component: () => import('@/views/profile/Profile.vue'),
  //   meta: {
  //     resource: 'PagesProfile',
  //     pageTitle: 'Profile',
  //     breadcrumb: [
  //       {
  //         text: 'Pages',
  //       },
  //       {
  //         text: 'Profile',
  //         active: true,
  //       },
  //     ],
  //   },
  // },

  {
    path: '/help/faq',
    name: 'help-faq',
    component: () => import('@/views/miscellaneous/ComingSoon.vue'),
    meta: {
      pageTitle: 'FAQ',
      breadcrumb: [
        {
          text: 'Help',
        },
        {
          text: 'FAQ',
          active: true,
        },
      ],
    },
  },
  {
    path: '/pages/knowledge-base',
    name: 'pages-knowledge-base',
    component: () => import('@/views/miscellaneous/ComingSoon.vue'),
    meta: {
      pageTitle: 'Knowledge Base',
      breadcrumb: [
        {
          text: 'Help',
        },
        {
          text: 'Knowledge Base',
          active: true,
        },
      ],
    },
  },
  // {
  //   path: '/pages/knowledge-base/:category',
  //   name: 'pages-knowledge-base-category',
  //   component: () => import('@/views/knowledge-base/KnowledgeBaseCategory.vue'),
  //   meta: {
  //     pageTitle: 'Category',
  //     breadcrumb: [
  //       {
  //         text: 'Support',
  //       },
  //       {
  //         text: 'Knowledge Base',
  //         to: '/pages/knowledge-base',
  //       },
  //       {
  //         text: 'Category',
  //         active: true,
  //       },
  //     ],
  //     navActiveLink: 'pages-knowledge-base',
  //   },
  // },
  // {
  //   path: '/pages/knowledge-base/:category/:slug',
  //   name: 'pages-knowledge-base-question',
  //   component: () => import('@/views/knowledge-base/KnowledgeBaseCategoryQuestion.vue'),
  //   meta: {
  //     pageTitle: 'Question',
  //     breadcrumb: [
  //       {
  //         text: 'Pages',
  //       },
  //       {
  //         text: 'Knowledge Base',
  //         to: '/pages/knowledge-base',
  //       },
  //       {
  //         text: 'Category',
  //         to: '/pages/knowledge-base/category',
  //       },
  //       {
  //         text: 'Question',
  //         active: true,
  //       },
  //     ],
  //     navActiveLink: 'pages-knowledge-base',
  //   },
  // },
]
