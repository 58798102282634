export default {
  async updateProjectsSelected({ commit }, project) {
    commit('UPDATE_PROJECTS_SELECTED', project)
  },

  async emptyProjectsSelected({ commit }) {
    commit('EMPTY_PROJECTS_SELECTED')
  },

  async saveSearchRefinement({ commit }, placeSearched) {
    commit('SAVE_SEARCH_QUERY', placeSearched)
  },

  async removeSearchRefinement({ commit }) {
    commit('REMOVE_SEARCH_QUERY')
  },
}
