import actions from './actions'
import mutations from './mutations'
import getters from './getters'

// This file holds the state. We are using a function to return the state, which will allow us to use multiple modules.
// and it will always create a new instance.

// we are using logged in and user and this will be our initial state. We will build on this.
const state = () => ({
  projectsSelected: [],
  placeSearched: undefined,
})

// As for the settings for this module we will so that will make all the actions, mutations and getters local.
// Purpose of namespaced  - https://stackoverflow.com/questions/47792212/what-exactly-is-namespacing-of-modules-in-vuex

export default {
  namespaced: true,
  actions,
  mutations,
  getters,
  state,
}

// Now that we have the skeleton for the authentication module. Lets create the actions, mutations and getters.
// Every time you create a new module you have to make those files, actions, getters and mutations.
