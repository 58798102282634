import gql from 'graphql-tag'

export const getCurrentUser = gql`query GetCurrentUser{
    getCurrentUser {
    cognitoID
    createdAt
    email
    firstName
    id
    lastName
    tenantID
    imageUrl
    }
  }`

export const getProduct = gql`query GetItem($id: ID!){
  getProduct(id: $id) {
    id
    name
    salesforceID
    version
    titleExpected
    titled
    street
    stage
    city
    postcode
    state
    street
    media {
      name
      originUrl
      type
    }
    project {
      media {
        id
        name
        salesforceID
        originUrl
        type
      }
      averagePrice
      description
      heroURL
      id
      latitude
      logoURL
      longitude
      name
      productCount
      vendorName
      version
    }
    depth
    frontage
    lotPlanUrl
    price
    size
    titled
  }
}`
