import { Auth } from 'aws-amplify'

// We can also use router so whenever user logs out we can automatically navigate to the route.

// you can trigger a state change on mutations with commit keyword.
// Note: actions can be synchronous or asynchronous, but mutations can only synchronous.

export default {
  // This will take the user and call a mutation using commit. This
  async saveUserGroup({ commit }, userGroup) {
    commit('SAVE_USER_GROUP', userGroup)
  },

  async saveUser({ commit }, user) {
    commit('SAVE_USER', user)
  },

  // Next action, logout actions it will be async with no arguments. It will call a mutation which will be user logout.
  async logoutUser({ commit }) {
    await Auth.signOut({
      // By doing this we are going to sign out from all devices and revoke all auth tokens.

    })
    // Once we have done this we are going to change the store. We will do this by calling commit user logout. no arguments for this call.
    commit('USER_LOGOUT')
  },

}
